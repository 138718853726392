<template>
  <div class="rounded-8" :class="{ 'is-content-visible': isContentVisible }">
    <div
      class="head d-flex align-items-center bg-white p-16"
      :class="{ 'cursor-pointer': announcement.content }"
      @click="isContentVisible = !isContentVisible"
    >
      <div
        class="
          date
          align-self-start
          bg-primary
          text-white text-center
          p-16
          rounded-8
          me-16
        "
      >
        <div class="day fw-bold">
          {{ announcement.createdAtJS.format('DD') }}
        </div>
        <div class="fs-11 text-nowrap text-uppercase">
          {{ announcement.createdAtJS.format('MMM YY') }}
        </div>
      </div>

      <div class="me-16">
        <h3 class="fs-20">
          {{ announcement.title }}
        </h3>

        <a
          class="fs-14 text-decoration-none"
          target="_blank"
          v-if="announcement.file"
          :href="announcement.fileURL"
          ><CloudDownloadIcon class="me-8" />File pengumuman</a
        >
      </div>

      <div class="fs-20 text-muted p-8 ms-auto" v-if="announcement.content">
        <ChevronUpIcon v-if="isContentVisible" />
        <ChevronDownIcon v-else />
      </div>
    </div>

    <RichTextContent
      class="body p-16"
      v-if="announcement.content"
      :content="announcement.content"
    />
  </div>
</template>

<script>
import RichTextContent from '../content/RichTextContent.vue';

import ChevronDownIcon from 'bootstrap-icons/icons/chevron-down.svg';
import ChevronUpIcon from 'bootstrap-icons/icons/chevron-up.svg';
import CloudDownloadIcon from 'bootstrap-icons/icons/cloud-download.svg';

export default {
  components: {
    RichTextContent,
    ChevronDownIcon,
    ChevronUpIcon,
    CloudDownloadIcon,
  },

  props: ['announcement'],

  data() {
    return { isContentVisible: false };
  },
};
</script>

<style lang="scss" scoped>
.head {
  border-radius: rem(8);

  .is-content-visible & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.body {
  display: none;
  background: radial-gradient(ellipse at 50% 100%, #efeff5, transparent)
    $body-bg;

  .is-content-visible & {
    display: block;
  }
}

.day {
  font-size: rem(36);
  line-height: 1;
}
</style>
