<template>
  <div v-html="content"></div>
</template>

<script>
export default {
  props: ['content'],
};
</script>

<style lang="scss" scoped>
div {
  ::v-deep {
    img {
      max-width: 100%;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
